@import url('https://fonts.googleapis.com/css2?family=Corben&display=swap');

.App {
  background-color: #f1cac5;
  min-height: 100vh;
  display: grid;
}

@keyframes fade_in {
  from {opacity: 0;}
  to {opacity:100;}
}