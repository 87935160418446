@import url('https://fonts.googleapis.com/css2?family=Corben&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');

.rbox {
    padding: 20px;
    max-width: fit-content;
    max-height: fit-content;
    margin: 0 auto;
    border: dashed;
    border-radius: 50px;
    margin-bottom: 50px;
    margin-top: 50px;
}

.title {
    font-size: 30px;
    font-weight: bold;
    margin-bottom: 10px;
    font-family: 'Jost', sans-serif;
    text-align: center;
}

.content {
    display: flex;
    align-items: center;
}

.image-wrapper {
    display: flex;
    flex-direction: column;
    margin-right: 20px;
}

.screenshotl {
    width: 200px;
    height: auto;
    margin-bottom: 20px;
}

.screenshotr {
    width: 200px;
    height: auto;
    margin-bottom: 20px;
    margin-left: 100px;
}

.image-wrapper img:hover {
    transition: transform .3s;
    transform: scale(1.08);
}

.text {
    flex: 1;
}

.text p {
    font-size: 16px;
    line-height: 1.6;
    font-family: 'Jost', sans-serif;
}

@media only screen and (max-width: 390px) {
    .rbox {
        padding: 20px;
        max-width: 85%;
        max-height: fit-content;
        margin: 0 auto;
        border: dashed;
        border-radius: 50px;
        margin-top: 100px;
    }

    .title {
        font-size: 20px;
        font-weight: bold;
        margin-bottom: 10px;
        font-family: 'Jost', sans-serif;
        text-align: center;
    }

    .content {
        display: inline;
        align-items: center;
    }

    .image-wrapper {
        display: inline;
        flex-direction: column;

    }

    .screenshotl {
        width: 40%;
        height: auto;

    }

    .screenshotr {
        width: 40%;
        height: auto;
    }

    .image-wrapper img:hover {
        transition: transform .3s;
        transform: scale(1.08);
    }

    .text {
        flex: 1;
    }

    .text p {
        font-size: 11px;
        line-height: 1;
        width: 100%;
        font-family: 'Jost', sans-serif;
    }
}

@media only screen and (min-width: 390px) {
    .rbox {
            padding: 20px;
            max-width: 85%;
            max-height: fit-content;
            border: dashed;
            border-radius: 50px;
        }
    
        .title {
            font-size: 20px;
            font-weight: bold;
            margin-bottom: 10px;
            font-family: 'Jost', sans-serif;
            text-align: center;
        }
    
        .content {
            display: inline;
            align-items: center;
        }
    
        .image-wrapper {
            display: inline;
            flex-direction: column;
    
        }
    
        .screenshotl {
            width: 40%;
            height: auto;
            
        }
    
        .screenshotr {
            width: 40%;
            height: auto;
        }
    
        .image-wrapper img:hover {
            transition: transform .3s;
            transform: scale(1.08);
        }
    
        .text {
            flex: 1;
        }
    
        .text p {
            font-size: 11px;
            line-height: 1;
            width: 100%;
            font-family: 'Jost', sans-serif;
        }

}

@media only screen and (min-width: 480px) {

    .rbox {
            padding: 20px;
            max-width: 85%;
            max-height: fit-content;
            border: dashed;
            border-radius: 50px;
        }
    
        .title {
            font-size: 30px;
            font-weight: bold;
            margin-bottom: 10px;
            font-family: 'Jost', sans-serif;
            text-align: center;
        }
    
        .content {
            display: inline;
            align-items: center;
        }
    
        .image-wrapper {
            display: flex;
            flex-direction: column;
            margin-right: 20px;
    
        }
    
        .screenshotl {
            width: 200px;
            height: auto;
            margin-bottom: 20px;
        }
    
        .screenshotr {
            width: 200px;
            height: auto;
            margin-bottom: 20px;
            margin-left: 100px;
        }
    
        .image-wrapper img:hover {
            transition: transform .3s;
            transform: scale(1.08);
        }
    
        .text {
            flex: 1;
        }
    
        .text p {
            font-size: 16px;
            line-height: 1.6;
            font-family: 'Jost', sans-serif;
        }

}

@media only screen and (min-width: 550px) {

    .rbox {
            padding: 20px;
            max-width: 80%;
            max-height: fit-content;
            border: dashed;
            border-radius: 50px;
            
        }
    
        .title {
            font-size: 30px;
            font-weight: bold;
            margin-bottom: 10px;
            font-family: 'Jost', sans-serif;
            text-align: center;
        }
    
        .content {
            display: inline;
            align-items: center;
        }
    
        .image-wrapper {
            display: flex;
            flex-direction: column;
            margin-right: 20px;
    
        }
    
        .screenshotl {
            width: 200px;
            height: auto;
            margin-bottom: 20px;
        }
    
        .screenshotr {
            width: 200px;
            height: auto;
            margin-bottom: 20px;
            margin-left: 100px;
        }
    
        .image-wrapper img:hover {
            transition: transform .3s;
            transform: scale(1.08);
        }
    
        .text {
            flex: 1;
        }
    
        .text p {
            font-size: 16px;
            line-height: 1.6;
            font-family: 'Jost', sans-serif;
        }

}


@media only screen and (min-width: 889px) {

    .rbox {
            padding: 20px;
            max-width: 85%;
            max-height: fit-content;
        }
    
        .title {
            font-size: 30px;
            font-weight: bold;
            margin-bottom: 10px;
            font-family: 'Jost', sans-serif;
            text-align: center;
        }
    
        .content {
            display: flex;
            align-items: center;
        }
    
        .image-wrapper {
            display: flex;
            flex-direction: column;
            margin-right: 20px;
    
        }
    
        .screenshotl {
            width: 200px;
            height: auto;
            margin-bottom: 20px;
        }
    
        .screenshotr {
            width: 200px;
            height: auto;
            margin-bottom: 20px;
            margin-left: 100px;
        }
    
        .image-wrapper img:hover {
            transition: transform .3s;
            transform: scale(1.08);
        }
    
        .text {
            flex: 1;
        }
    
        .text p {
            font-size: 16px;
            line-height: 1.6;
            font-family: 'Jost', sans-serif;
        }
}

@media only screen and (min-width: 1200px) {

.rbox {
        padding: 20px;
        max-width: 60%;
        max-height: fit-content;
    }

    .title {
        font-size: 30px;
        font-weight: bold;
        margin-bottom: 10px;
        font-family: 'Jost', sans-serif;
        text-align: center;
    }

    .content {
        display: flex;
        align-items: center;
    }

    .image-wrapper {
        display: flex;
        flex-direction: column;
        margin-right: 20px;

    }

    .screenshotl {
        width: 200px;
        height: auto;
        margin-bottom: 20px;
    }

    .screenshotr {
        width: 200px;
        height: auto;
        margin-bottom: 20px;
        margin-left: 100px;
    }

    .image-wrapper img:hover {
        transition: transform .3s;
        transform: scale(1.08);
    }

    .text {
        flex: 1;
    }

    .text p {
        font-size: 16px;
        line-height: 1.6;
        font-family: 'Jost', sans-serif;
    }
}

/* Extra big screen devices (1800px and above) */
@media only screen and (min-width: 1800px) {

    .rbox {
            max-width: 60%;
            max-height: fit-content;
        }
    
        .title {
            font-size: 30px;
            margin-bottom: 10px;
        }
    
        .content {
            display: flex;
            align-items: center;
        }
    
        .image-wrapper {
            display: flex;
            flex-direction: column;
            margin-right: 20px;
    
        }
    
        .screenshotl {
            width: 200px;
            height: auto;
            margin-bottom: 20px;
        }
    
        .screenshotr {
            width: 200px;
            height: auto;
            margin-bottom: 20px;
            margin-left: 100px;
        }
    
        .image-wrapper img:hover {
            transition: transform .3s;
            transform: scale(1.08);
        }
    
        .text {
            flex: 1;
        }
    
        .text p {
            font-size: 16px;
            line-height: 1.6;
        }
}

/* Extra big screen devices (2000px and above) */
@media only screen and (min-width: 2000px) {

    .rbox {
            max-width: 50%;
            max-height: fit-content;
        }
    
        .title {
            font-size: 30px;
            margin-bottom: 10px;
        }
    
        .content {
            display: flex;
            align-items: center;
        }
    
        .image-wrapper {
            display: flex;
            flex-direction: column;
            margin-right: 20px;
    
        }
    
        .screenshotl {
            width: 200px;
            height: auto;
            margin-bottom: 20px;
        }
    
        .screenshotr {
            width: 200px;
            height: auto;
            margin-bottom: 20px;
            margin-left: 100px;
        }
    
        .image-wrapper img:hover {
            transition: transform .3s;
            transform: scale(1.08);
        }
    
        .text {
            flex: 1;
        }
    
        .text p {
            font-size: 16px;
            line-height: 1.6;
        }
}

/* Extra big screen devices (1800px and above) */
@media only screen and (min-width: 2500px) {

    .rbox {
            max-width: 40%;
            max-height: fit-content;
        }
    
        .title {
            font-size: 30px;
            margin-bottom: 10px;
        }
    
        .content {
            display: flex;
            align-items: center;
        }
    
        .image-wrapper {
            display: flex;
            flex-direction: column;
            margin-right: 20px;
    
        }
    
        .screenshotl {
            width: 200px;
            height: auto;
            margin-bottom: 20px;
        }
    
        .screenshotr {
            width: 200px;
            height: auto;
            margin-bottom: 20px;
            margin-left: 100px;
        }
    
        .image-wrapper img:hover {
            transition: transform .3s;
            transform: scale(1.08);
        }
    
        .text {
            flex: 1;
        }
    
        .text p {
            margin-left: 230px;
            font-size: 16px;
            line-height: 1.6;
            width: 65%;
        }
}