@import url('https://fonts.googleapis.com/css2?family=Corben&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Jost&display=swap');

.Contact-header {
    background-color: #f1cac5;
    display: grid;
    min-height: 100vh;
    font-size: calc(10px + 4.5vmin);
    font-family: 'Corben', cursive;
    color: rgba(70, 68, 68, 0.815);
    grid-template-columns: 1fr,1fr;
}

.popup-content {
    font-family: 'Jost', sans-serif;
    color: rgba(70, 68, 68, 0.815);
    animation-name: fade_in;
    animation-duration: 0.5s;
}

.icons-container {
    grid-column:  1/3;
    justify-content: center;
    align-items: center;
    display: flex;
}

.icons {
    width: calc(4vmin);
    margin-left: calc(4vh);
    margin-right: calc(4vh);
    transition: transform .2s;
    
}

.icons:hover {
    transform: scale(1.25);
}

.contactpic {
    margin-top: 20px;
    max-width: 40%;
    max-height: 750px;
    border-radius: 50%;
    animation-name: fade_in, transition_in;
    animation-duration: 2s;
    grid-column: 1/3;
    justify-self: center;
}

.contactheading {
    animation-name: fade_in;
    animation-duration: 2s;
}

.intro {
    font-family: 'Jost', sans-serif;
    font-size: calc(8px + 1vmin);
    text-align: left;
    width: 60%;
    grid-column: 1/3;
    justify-self: center;
}

@keyframes fade_in {
    from {
        opacity: 0;
    }

    to {
        opacity: 100;
    }
}

@keyframes transition_in {
    from {
        transform: translateY(-40px);
    }
    to {
        transfrom: translateY(0px);
    }
}

@media only screen and (max-width: 389px) {
    .contactpic {
        max-width: 60%;
        max-height: 750px;
    }
    .intro {
        width: 75%;
    }
    .icons {
        width: calc(7.5vmin);
    }
}

@media only screen and (min-width: 390px) {
    .contactpic {
        max-width: 50%;
        max-height: 750px;
    }
    
}

@media only screen and (min-width: 480px) {
    .contactpic {
        max-width: 50%;
        max-height: 750px;
    }
}

@media only screen and (min-width: 550px) {
    .contactpic {
        max-width: 50%;
        max-height: 750px;
    }
    .intro {
        width: 60%;
    }
    .icons {
        width: calc(4.5vmin);
    }
}

@media only screen and (min-width: 889px) {
    .contactpic {
        max-width: 40%;
        max-height: 750px;
    }
}

@media only screen and (min-width: 1200px) {
    .contactpic {
        max-width: 35%;
        max-height: 750px;
    }

    .intro {
        width: 40%;
    }
}

@media only screen and (min-width: 1600px) {
    .contactpic {
        max-width: 29%;
        max-height: 750px;
    }

    .intro {
        width: 30%;
    }
}

@media only screen and (min-width: 1800px) {
    .contactpic {
        max-width: 30%;
        max-height: 750px;
    }

    .intro {
        width: 30%;
    }
}

@media only screen and (min-width: 2000px) {
}

@media only screen and (min-width: 2500px) {
}


